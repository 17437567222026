<template>
  <v-card>
    <v-card-title>
      <span class="section-title">Integração</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-spacer />
        <v-col class="pa-0" justify="center" cols="10" style="text-align:center; float:left;">
          <doughnut-chart class="chart" :chart-data="chartData" :options="chartConfig" />
        </v-col>
        <v-spacer />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
    DoughnutChart: () => import('@/components/charts/DoughnutChart.vue')
  },
  props: {
    accounts: Array
  },
  computed: {
    chartLabels() {
      return ['PDV', 'Retaguarda', 'Híbrido', 'Não detectado']
    },
    chartAcc() {
      const mapSituation = this.accounts.map(account => {
        if (account?.integration_type === 'full_pos') return 'PDV'
        if (account?.integration_type === 'backoffice') return 'Retaguarda'
        if (account?.integration_type === 'hybrid') return 'Híbrido'
        else return 'Não detectado'
      })

      return mapSituation.reduce((acc, account) => (!account ? acc : { ...acc, [account]: (acc[account] || 0) + 1 }), {})
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: [
          {
            data: this.chartLabels.map(label => this.chartAcc[label] || 0),
            backgroundColor: ['#339af0', '#20c997', '#FFF176', '#adb5bd'],
            borderWidth: 1,
            datalabels: {
              display: true
            }
          }
        ]
      }
    },
    chartConfig() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true
        },
        legend: {
          labels: {
            usePointStyle: true
          }
        },
        hover: {
          animationDuration: 0
        },
        events: ['click', 'mousemove'],
        responsiveAnimationDuration: 0,
        plugins: {
          datalabels: {
            backgroundColor: 'rgba(248, 249, 250, 0.82)',
            anchor: 'end',
            align: 'start',
            borderRadius: 4,
            color: 'rgba(0, 0, 0, 0.54)',
            formatter: (value, context) => {
              const percent = _.round((value / _.sum(context.dataset.data)) * 100)
              return `${value} - ${percent}%`
            }
          }
        }
      }
    }
  }
}
</script>
